import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Seo from '@/components/Seo'
import { Seo as SeoType } from '@/types.d'
import { Gutenberg } from '@/components/Gutenberg'

interface HomeData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      featuredHeroImage?: any
      seo: SeoType
      featuredImage: any
      blocks: any
      blocksJSON: any
    }
    mainMenu?: any
  }
  pageContext: {
    postlang: string
  }
}

const SingleWheelInfo = ({ data }: HomeData) => {
  // Page data
  const { page } = data

  const { mainMenu } = data

  const heroContent = {
    image: page?.featuredImage,
  }

  return (
    <Layout backPath={'/yleista-renkaista'} title={page?.title} hero={heroContent} nav={mainMenu}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Gutenberg blocks={page?.blocks} />
    </Layout>
  )
}

export const SingleWheelInfoQuery = graphql`
  query WheelInfoById($id: String) {
    page: wpGwWheelInfo(id: { eq: $id }) {
      ...featuredHeroImageWheelInfo
      id
      content
      title
      uri
      ...seoWheelInfo
      ...wheelInfoBlocks
    }
    mainMenu: wpMenu(slug: { eq: "main-menu" }) {
      ...navigation
    }
  }
`

export default SingleWheelInfo
